import React, {useEffect, useState} from 'react';
import '../Styles.scss';
import './HomePage.scss';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import InfoCard from "../InfoCard/InfoCard";
import Form from "../Form/Form";
// import InfoCard from "../InfoCard/InfoCard";
// import Form from "../Form/Form";



function HomePage(this: any) {
    const [width, setWidth] = useState(window.innerWidth);

    const [isDesktop, setDesktop] = useState((window.innerWidth >= 1024) && (window.innerWidth <= 1920));
    const [isTablet, setTablet] = useState(((window.innerWidth > 414) && (window.innerWidth < 1024)));
    const [isMobile, setMobile] = useState((window.innerWidth >= 360) && (window.innerWidth <= 414));

    const updateMedia = () => {
        setWidth(window.innerWidth);
        setDesktop((width >= 1024 ) && (width <= 1920));
        setTablet((width  > 414) && (width < 1024));
        setMobile((width >= 360) && (width <= 414));
        console.log(isMobile);
        console.log(isTablet);
    };


    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    useEffect(() => {
        function clean() {

        }
        // clean up code
        window.removeEventListener('scroll', clean);
        // window.addEventListener('scroll', onScroll, { passive: true });

        window.addEventListener(
            "scroll",
            () => {
                const test = window.scrollY / (document.body.offsetHeight - window.innerHeight);
                document.body.style.setProperty(
                    "--scroll", test.toString()
                );
            },
            { passive: true }
        );
        return () => window.removeEventListener('scroll', clean);
    }, []);

    return (
        <div>
            {/* HEADING WITH IMAGE */}
            <Header isMobile={isMobile} isDesktop={isDesktop}/>
            {/* INTRODUCTION */}
            {/*<Intro isMobile={isMobile} isDesktop={isDesktop}/>*/}
            {/* US DIAGRAM */}
            <div className={'sec'}>
                <img src={'./USDiagram.png'} style={{width: 'auto', height: (isMobile || isTablet) ? '40%' : '90%'}} alt={"USA map that says '617,000 bridges'"}/>
            </div>
            {/* BRIDGE INFO CALLOUT*/}
            <div className={'sec callout'}>
                <div className={'sec callout-div text'}>
                    <p>
                        Each of these
                        <span style={{color: 'var(--yellow)'}}> 617,000 </span>
                        bridges must be inspected at least once every two years.
                    </p>
                </div>
            </div>
            {/*  CARDS  */}
            <div className={'card-sec'} style={{justifyContent: 'center'}}>
                <InfoCard boldWord={'expensive'}/>
                <InfoCard boldWord={'time intensive'}/>
                <InfoCard boldWord={'dangerous'}/>
            </div>
            {/* SCROLLING TEXT */}
            <div className={'scrolling-words'}>
                <div className={'scrolling-words container'}>
                    <span>Inspections don't have to be </span>
                    <div className={'scrolling-words-box'}>
                        <ul>
                            <li>COSTLY</li>
                            <li>TIME INTENSIVE</li>
                            <li>DANGEROUS</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* BRIDGE VECTOR*/}
            <div className={'sec'}>
                <img src={'./BridgeVector1.jpg'} style={{width: '90%', height: 'auto'}} alt={"Bridge with a drone next to it"}/>
            </div>
            {/* INFO */}
            <div className={'info-sec'}>
                <div style={{width: isMobile ? '85%' : '70%', height: '100%', flexDirection: 'column', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end', alignItems:'center'}}>
                    <div className={'header'} style={{alignSelf: isMobile ? 'center' : 'flex-start'}}>
                        Nice to meet you!
                    </div>
                    <div className={'subheader'}>
                        We are a startup dedicated to improving the inspection process.
                    </div>
                    <div className={'body'}>
                        Infrastructure inspection is critical to protecting our nation’s bridges. We want to provide inspectors with an autonomous solution to data collection, making the process safer, cost effective, and time efficient.                    </div>
                </div>
            </div>
            {/*/!*  DRONE  *!/*/}
            {/*<div className={'drone-sec'}>*/}
            {/*    /!*  BENEFITS  *!/*/}
            {/*    <div className={'benefits-sec'}>*/}
            {/*        <div className={'benefits-left'}>*/}
            {/*            <div style={{background: 'var(--yellow)', width: '5px', height: '250px'}}/>*/}
            {/*            <div style={{flexDirection: 'column', marginLeft: '40px', color: 'var(--white)'}}>*/}
            {/*                <div>CLEAN DATA</div>*/}
            {/*                <div>REPEATABLE</div>*/}
            {/*                <div>COST EFFECTIVE</div>*/}
            {/*                <div>TIME EFFICIENT</div>*/}
            {/*                <div>SAFE</div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <img src={'Drone.png'} alt={'Drone icon'} style={{width: '30vw', height: '30vh'}}/>*/}

            {/*</div>*/}
            {/*  CONTACT*/}

            <div className={'info-sec'}>
                <div className={'info-sec content'}>
                    <b className={'header'}>
                        Want to know more?
                    </b>
                    <div className={'subheader'}>
                        Keep in touch!
                    </div>
                    <Form/>
                </div>
            </div>
            <Footer isMobile={isMobile} isDesktop={isDesktop}/>

            {/*/!*  DRONE  *!/*/}
            {/*<div className={'drone-sec'}>*/}
            {/*    <img src={'Drone.png'} alt={'Drone icon'} style={{width: '30vw', height: '30vh'}}/>*/}
            {/*</div>*/}
            {/*/!*  BENEFITS  *!/*/}
            {/*<div className={'benefits-sec'}>*/}
            {/*    <div className={'benefits-left'}>*/}
            {/*        <div style={{background: 'var(--yellow)', width: '5px', height: '250px'}}/>*/}
            {/*        <div style={{flexDirection: 'column', marginLeft: '40px', color: 'var(--white)'}}>*/}
            {/*            <div>CLEAN DATA</div>*/}
            {/*            <div>REPEATABLE</div>*/}
            {/*            <div>COST EFFECTIVE</div>*/}
            {/*            <div>TIME EFFICIENT</div>*/}
            {/*            <div>SAFE</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default HomePage;
