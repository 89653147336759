import React from 'react';
import '../Styles.scss';
import './InfoCard.scss';

interface InfoCardProps {
    boldWord: string;
}

const InfoCard = (props: InfoCardProps) => {
    return(
        // <div className={'button-sty'}>
            <div className="d-1">
                <span >
                    Inspections
                    are

                </span>
                <span style={{alignSelf: 'flex-start', color: 'var(--yellow)'}}>
                    {props.boldWord + '.'}
                </span>

            </div>
        // </div>
    );
}

export default InfoCard;