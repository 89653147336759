import React, {useEffect} from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./HomePage/HomePage";

function App() {
    useEffect(() => {
        document.title = "Beacon"
    }, []);
  return (


      <BrowserRouter>
          <Routes>
              <Route path="/" element={<HomePage />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
