import React,  { useState } from 'react';
import './Form.scss';
import handleSubmit from '../handles/handlesubmit';

function Form() {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleSubmit(name, company, email);
        setName('');
        setCompany('');
        setEmail('');
    }

    return (
        <div className={"intake-form"}>
            <form onSubmit={handleFormSubmit}>
                <input className={"inputbox"} placeholder="Name" type="text" name="name" required value={name} onChange={(e) => setName(e.target.value)} />
                <input className={"inputbox"} placeholder="Company" type="text" name="company" required value={company} onChange={(e) => setCompany(e.target.value)} />
                <input className={"inputbox"} type="email" placeholder="Email" required value={email} onChange={(e) => setEmail(e.target.value)} />

                <button type="submit" className={"submit-button"}>
                    Submit
                </button>
            </form>
        </div>
    );
}

export default Form;