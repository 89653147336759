import { addDoc, collection } from "@firebase/firestore"
import { firestore } from "../firebase_setup/firebase"
 
const handleSubmit = async (name: string, company: string, email: string): Promise<void> => {
    try {
        const ref = collection(firestore, "conference_data");
        await addDoc(ref, { // Use addDoc function to add a new document
            Name: name,
            Company: company,
            Email: email
        });
        console.log("Document added successfully!");
    } catch (error) {
        console.error("Error adding document: ", error);
    }
}

export default handleSubmit