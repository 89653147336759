import React from "react";
import "./Footer.scss";

interface ScreenProps {
    isMobile: boolean;
    isDesktop: boolean;
}

const Footer = (props: ScreenProps) => {
    return (<>
        {props.isMobile ? (<div className={'footer-sec'}>
                    <img src={'WhiteLogo.png'} style={{width: "150px", height: "auto"}} alt={'Beacon Logo'}/>
                    <div style={{backgroundColor: 'transparent', width: '96%', display: 'flex', justifyContent:'flex-end'}}>
                        <a href={"https://www.linkedin.com/company/beacon-drones/"} style={{textDecoration: 'none', width: 'fit-content'}}>
                            <button className={'footer-sec linkedin-button'}>
                                <div style={{color: 'white', fontSize: 'var(--body-font)'}}>
                                    Find us on LinkedIn
                                </div>
                                <img src={'linkedin.png'} className={'linkedin-img'} alt={'Linked In Logo'}/>
                            </button>
                        </a>
                    </div>
                </div>
            ) :

            <div className={'footer-sec'}>
                <img src={'WhiteLogo.png'} style={{width: "20%", height: "auto"}} alt={'Beacon Logo'}/>
                <div style={{backgroundColor: 'transparent'}}>
                    <a href={"https://www.linkedin.com/company/beacon-drones/"} style={{textDecoration: 'none'}}>
                        <button className={'footer-sec linkedin-button'}>
                            <div style={{color: 'white', fontSize: 'var(--body-font)'}}>
                                Find us on LinkedIn
                            </div>
                            <img src={'linkedin.png'} className={'linkedin-img'} alt={'Linked In Logo'}/>
                        </button>
                    </a>
                </div>


            </div>
        }</>);
};

export default Footer;