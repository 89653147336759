import React from "react";
import "./Header.scss";

interface ScreenProps {
    isMobile: boolean;
    isDesktop: boolean;
}

const Header = (props: ScreenProps) => {
    return (<>
            {props.isMobile ? (<div className={'heading-sec'}>
                        <img src={'YellowHexagonHeaderMobile.png'}  alt={'Beacon logo'} style={{width: '80%', height: 'auto'}}/>
                        <div className={'heading text'}>
                    <span>
                        INFRASTRUCTURE INSPECTION
                    </span>
                            <span style={{color: "var(--yellow)"}}>
                        SIMPLIFIED.
                    </span>
                        </div>
                    </div>
                ) :

                <div className={'heading-sec'}>
                    <div className={'heading text'}>
                    <span>
                        INFRASTRUCTURE INSPECTION
                    </span>
                        <span style={{color: "var(--yellow)"}}>
                        SIMPLIFIED.
                    </span>
                    </div>
                    <img src={props.isDesktop ? 'YellowHexagonHeader.png' : 'YellowHexagonHeaderMobile.png'} className={'heading'}
                         alt={'Beacon logo'}/>
                </div>
            }</>);
};

export default Header;